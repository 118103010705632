<mat-sidenav-container  style="height: 100%;">

  <mat-sidenav-content>

    <div class="max-contenitore"  style="position: fixed; width: 100%; z-index: 99;">
      <mat-toolbar style="background: white; width: 100%; box-shadow: 0px 10px 12px #e4e3e3; border-bottom-left-radius: 25px; border-bottom-right-radius: 25px;" class="position-fixed">
        <span style="display: block; line-height: 4vw;">
          <p id="id-card">{{activeValore?.CODICE1}}</p>
        </span>
    </mat-toolbar>

      </div>

    <div class="content">

      <form #f="ngForm" class="seconda-parte">
        <mat-form-field class="example-full-width">
          <mat-label [ngStyle]="{'color': block === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" class="label-inp"><strong>Codice:</strong></mat-label>
          <input  [ngModel]="activeValore?.CODICE1 === '' ? '---': activeValore?.CODICE1" name="owner" id="valore-ritorno" matInput placeholder="" value="{{activeValore?.CODICE1}}" style="color: #000;" [disabled]="block">
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label [ngStyle]="{'color': block === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" class="label-inp">Note Fornitore:</mat-label>
          <input  [ngModel]="activeValore?.NomeFornitore === '' ? '---': activeValore?.NomeFornitore" name="owner" id="valore-ritorno" matInput placeholder="" value="{{activeValore?.NomeFornitore}}" style="color: #000;" [disabled]="block">
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label [ngStyle]="{'color': block === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" class="label-inp">Codice BI:</mat-label>
          <input  [ngModel]="activeValore?.CodiceBI === '' ? '---': activeValore?.CodiceBI" name="versione" id="valore-ritorno" matInput placeholder="" value="{{activeValore?.CodiceBI}}" style="color: #000;" [disabled]="block">
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label [ngStyle]="{'color': block === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" class="label-inp">Codice Requisito:</mat-label>
          <input  [ngModel]="activeValore?.CodiceRequisito === '' ? '---': activeValore?.CodiceRequisito" name="sviluppo" id="valore-ritorno" matInput placeholder="" value="{{activeValore?.CodiceRequisito}}" style="color: #000;" [disabled]="block">
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label [ngStyle]="{'color': block === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" class="label-inp">Codice Richiesta del Requisito:</mat-label>
          <input  [ngModel]="activeValore?.CodiceRichiestaRequisito === '' ? '---': activeValore?.CodiceRichiestaRequisito" name="design" id="valore-ritorno" matInput placeholder="" value="{{activeValore?.CodiceRichiestaRequisito}}" style="color: #000;" [disabled]="block">
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label [ngStyle]="{'color': block === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" class="label-inp">Codice Richiesta Valorizzazione:</mat-label>
          <input  [ngModel]="activeValore?.CodiceRichiestaValorizzazione === '' ? '---': activeValore?.CodiceRichiestaValorizzazione" name="anno" id="valore-ritorno" matInput placeholder="" value="{{activeValore.CodiceRichiestaValorizzazione}}" style="color: #000;" [disabled]="block">
        </mat-form-field>

        <!-- <mat-form-field class="example-full-width">
          <mat-label [ngStyle]="{'color': block === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" class="label-inp">Descrizione BP:</mat-label>
          <input  [ngModel]="activeValore.DescrizioneBP === '' ? '---': activeValore.DescrizioneBP" name="mese" id="valore-ritorno" matInput placeholder="" value="{{activeValore.DescrizioneBP}}" style="color: #000;" [disabled]="block">
        </mat-form-field> -->

        <mat-form-field class="example-full-width">
          <mat-label [ngStyle]="{'color': block === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" class="label-inp">Descrizione BP:</mat-label>
          <textarea style="overflow: auto; height: 30px; font-size: 12px; resize: none; color: #000; width: 100%; text-align: justify;" [ngModel]="activeValore.DescrizioneBP === '' ? '---':activeValore.DescrizioneBP"
        matInput placeholder="" name="note"
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="5" ></textarea>
        </mat-form-field>


        <mat-form-field class="example-full-width">
          <mat-label [ngStyle]="{'color': block === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" class="label-inp">Descrizione Requisito:</mat-label>
          <textarea style="overflow: auto; height: 30px; font-size: 12px; resize: none; color: #000; width: 100%; text-align: justify;" [ngModel]="activeValore.DescrizioneRequisito === '' ? '---':activeValore.DescrizioneRequisito"
        matInput placeholder="" name="note"
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="5" ></textarea>
        </mat-form-field>


        <!-- <mat-form-field class="example-full-width">
          <mat-label [ngStyle]="{'color': block === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" class="label-inp">Descrizione Requisito:</mat-label>
          <input  [ngModel]="activeValore.DescrizioneRequisito === '' ? '---': activeValore.DescrizioneRequisito" name="tiposervizio" id="valore-ritorno" matInput placeholder="" value="{{activeValore.DescrizioneRequisito}}" style="color: #000;" [disabled]="block">
        </mat-form-field> -->

        <mat-form-field class="example-full-width">
          <mat-label [ngStyle]="{'color': block === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" class="label-inp">Stato Requisito:</mat-label>
          <input  [ngModel]="activeValore.StatoRequisito === '' ? '---': activeValore.StatoRequisito" name="dashboard" id="valore-ritorno" matInput placeholder="" value="{{activeValore.StatoRequisito}}" style="color: #000;" [disabled]="block">
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label [ngStyle]="{'color': block === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" class="label-inp">Stato Valorizzazione:</mat-label>
          <input  [ngModel]="activeValore?.StatoValorizzazione === '' ? '---': activeValore?.StatoValorizzazione" name="idX" id="valore-ritorno" matInput placeholder="" value="{{activeValore?.StatoValorizzazione}}" style="color: #000;" [disabled]="block">
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label [ngStyle]="{'color': block === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" class="label-inp">Valore Totale:</mat-label>
          <input  [ngModel]="activeValore?.ValoreTotale === '' ? '---': activeValore?.ValoreTotale" name="esercizio" id="valore-ritorno" matInput placeholder="" value="{{activeValore?.ValoreTotale}}" style="color: #000;" [disabled]="block">
        </mat-form-field>

        <div class="pulsanti">
          <button *ngIf="!block" style="float: left; margin-top: 2vw; margin-right: 5vw; margin-bottom: 25vw;" mat-raised-button type="submit" >Salva e Modifica</button>
          <button *ngIf="!block" style="float: right; margin-top: 2vw; margin-right: 5vw; margin-bottom: 25vw;" mat-raised-button color="warn" (click)="returnEdit(f)">Annulla</button>
        </div>

      </form>

<div id="edit">
          <i id="modifica" class="fa fa-edit" (click)="openDialog()"></i>
        </div>

    </div>

  <footer>
    <div id="back">
      <span class="material-icons return" (click)="returnRelease()">
        west
      </span>
    </div>
  </footer>

  </mat-sidenav-content>

</mat-sidenav-container>
