import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SocialAuthService } from "angularx-social-login";
import { Router } from '@angular/router';
import { AuthService } from './service/auth.service';



@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(private authService: SocialAuthService, private router:Router,private auth: AuthService){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkLogin();
  }

  private checkLogin(){
    if (!this.auth.notExpired()){
      //this.router.navigate(['/splash']);
      return false;
    }
    return true;

  }



}
