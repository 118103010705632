
 <mat-sidenav-container style="height: 100%;" >
  <mat-sidenav-content>
    <div class="max-contenitore"  style="position: fixed; width: 100%; z-index: 99;">
      <mat-toolbar  class="position-fixed" style="background-color: white; height: 20vw;">
        <div class="intestazione">
          <img src="assets/img/timBlu.svg" style="margin-left: 5vw; margin-right: 2vw;">
        <!-- <div style="color: #0032a1; margin-left: 5px; line-height: 4vw; font-size: 11px; resize: none;"> <strong>OMNIWATCH</strong>  <div>Projects</div></div> -->
        </div>
      </mat-toolbar>
    </div>
    <div class="background">
      <mat-card style="width: 70vw; box-shadow: none; margin-top: 50%;">
          <h2 id="title-card">Sign In</h2>
          <h4 id="subtitle-card">Usa la tua email TIM e le tue credenziali di posta per effettuare il login</h4>
        <div class="tasto">
          <a class="btn btn-outline-info" role="button" style="text-transform:none; font-size: 13pt;width:100%" (click)="signInWithGoogle($event)">
            <img width="20px" style="margin-bottom:3px; margin-right:5px;" alt="Google sign-in" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png" />
            Login with Google
          </a>
        </div>
        <div class="tasto">
          <a class="btn btn-outline-info" role="button" style="text-transform:none; font-size: 13pt;width:100%" (click)="signInWithMicrosoft($event)">
            <img width="20px" style="margin-bottom:3px; margin-right:5px;" alt="Google sign-in" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Microsoft_logo.svg/600px-Microsoft_logo.svg.png" />
            Login with Microsoft
          </a>
        </div>
    </mat-card>
    <div>
      <div class="alert alert-danger text-center" style="width: 60vw; margin: auto;" *ngIf="showMsg">
        {{showMsg}}
      </div>
    </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
