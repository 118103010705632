<mat-sidenav-container  style="height: 100%;">
  <mat-sidenav-content>
    <div class="max-contenitore"  style="position: fixed; width: 100%; z-index: 99;">
      <mat-toolbar style="background: white; width: 100%; box-shadow: 0px 10px 12px #e4e3e3; border-bottom-left-radius: 25px; border-bottom-right-radius: 25px;" class="position-fixed">
        <span style="display: block; line-height: 4vw;">
          <p id="id-card">{{activeProdotto?.ID}}</p>
        </span>
    </mat-toolbar>

      </div>

    <div class="content">

      <form #f="ngForm" class="seconda-parte">
        <mat-form-field class="example-full-width">
          <mat-label [ngStyle]="{'color': block === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" class="label-inp"><strong>ID:</strong></mat-label>
          <input  [ngModel]="activeProdotto?.ID === '' ? '---': activeProdotto?.ID" name="owner" id="valore-ritorno" matInput placeholder="" value="{{activeProdotto?.ID}}" style="color: #000;" [disabled]="block">
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label [ngStyle]="{'color': block === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" class="label-inp">ID-Versione:</mat-label>
          <input  [ngModel]="activeProdotto?.IDVersione === '' ? '---': activeProdotto?.IDVersione" name="versione" id="valore-ritorno" matInput placeholder="" value="{{activeProdotto?.IDVersione}}" style="color: #000;" [disabled]="block">
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label [ngStyle]="{'color': block === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" class="label-inp">Data Completamento Sviluppo:</mat-label>
          <input  [ngModel]="activeProdotto?.DataCompletamentoSviluppo === '' ? '---': activeProdotto?.DataCompletamentoSviluppo" name="sviluppo" id="valore-ritorno" matInput placeholder="" value="{{activeProdotto?.Data_Completamento_Fase_Sviluppo}}" style="color: #000;" [disabled]="block">
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label [ngStyle]="{'color': block === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" class="label-inp">Data Completamento Design:</mat-label>
          <input  [ngModel]="activeProdotto?.DataCompletamentoDesign === '' ? '---': activeProdotto?.DataCompletamentoDesign" name="design" id="valore-ritorno" matInput placeholder="" value="{{activeProdotto?.Data_Completamento_Fase_Design}}" style="color: #000;" [disabled]="block">
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label [ngStyle]="{'color': block === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" class="label-inp">Anno Completamento Sviluppo:</mat-label>
          <input  [ngModel]="activeProdotto?.AnnoCompletamentoSviluppo === '' ? '---': activeProdotto?.AnnoCompletamentoSviluppo" name="anno" id="valore-ritorno" matInput placeholder="" value="{{activeProdotto?.AnnoCompletamentoSviluppo}}" style="color: #000;" [disabled]="block">
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label [ngStyle]="{'color': block === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" class="label-inp">Mese Completamento Sviluppo:</mat-label>
          <input  [ngModel]="activeProdotto?.MeseCompletamentoSviluppo === '' ? '---': activeProdotto?.MeseCompletamentoSviluppo" name="mese" id="valore-ritorno" matInput placeholder="" value="{{activeProdotto?.MeseCompletamentoSviluppo}}" style="color: #000;" [disabled]="block">
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label [ngStyle]="{'color': block === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" class="label-inp">Tipo Servizio:</mat-label>
          <input  [ngModel]="activeProdotto?.TipoServizio === '' ? '---': activeProdotto?.TipoServizio" name="tiposervizio" id="valore-ritorno" matInput placeholder="" value="{{activeProdotto?.Tipo_Servizio}}" style="color: #000;" [disabled]="block">
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label [ngStyle]="{'color': block === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" class="label-inp">Dashboard:</mat-label>
          <input  [ngModel]="activeProdotto?.Dashboard === '' ? '---': activeProdotto?.Dashboard" name="dashboard" id="valore-ritorno" matInput placeholder="" value="{{activeProdotto?.Dashboard}}" style="color: #000;" [disabled]="block">
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label [ngStyle]="{'color': block === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" class="label-inp">Disponibile:</mat-label>
          <input  [ngModel]="activeProdotto?.Disponibile === '' ? '---': activeProdotto?.Disponibile" name="idX" id="valore-ritorno" matInput placeholder="" value="{{activeProdotto?.ID_X}}" style="color: #000;" [disabled]="block">
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label [ngStyle]="{'color': block === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" class="label-inp">Esercizio:</mat-label>
          <input  [ngModel]="activeProdotto?.Esercizio === '' ? '---': activeProdotto?.Esercizio" name="esercizio" id="valore-ritorno" matInput placeholder="" value="{{activeProdotto?.Esercizio}}" style="color: #000;" [disabled]="block">
        </mat-form-field>

        <div class="pulsanti">
          <button *ngIf="!block" style="float: left; margin-top: 2vw; margin-right: 5vw; margin-bottom: 25vw;" mat-raised-button type="submit" >Salva e Modifica</button>
          <button *ngIf="!block" style="float: right; margin-top: 2vw; margin-right: 5vw; margin-bottom: 25vw;" mat-raised-button color="warn" (click)="returnEdit(f)">Annulla</button>
        </div>





      </form>

<div id="edit">
          <i id="modifica" class="fa fa-edit" (click)="openDialog()"></i>
        </div>

    </div>

  <footer>
    <div id="back">
      <span class="material-icons return" (click)="returnRelease()">
        west
      </span>
    </div>
  </footer>

  </mat-sidenav-content>

</mat-sidenav-container>
