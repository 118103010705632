import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SocialAuthService, SocialUser } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { map } from "rxjs/operators"

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  betoken:"";
  loggedIn: boolean;

  private reportUrl        = environment.reportUrl;
  private reportsUrl        = environment.reportsUrl;
  private ifAuthedUrl      = environment.ifAuthedUrl;





  constructor(public authService:SocialAuthService, private http: HttpClient) { }

  signintobackend(user){
       let serverurl=environment.signintobackendUrl;
       /* let options = {"Content-Type" : "application/json"} */
       let payload = {"idtoken" : user["idToken"]}
       let options : HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('x-access-token', user.idToken);
       return this.http.post(serverurl, payload, {headers: options, withCredentials: true});
       //return this.http.get(serverurl);
  }

  signinGoogle(token){
    let serverurl=environment.loginGoogle;
    console.log("signinMs")
    console.log(token)
    let payload = {}
    let options : HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('x-access-token', token);
    return this.http.post(serverurl, payload, {headers: options, withCredentials: true});
  }


  signinMS(token){
    let serverurl=environment.loginMs;
    console.log("signinMs")
    console.log(token)
    let payload = {}
    let options : HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('x-access-token', token);
    return this.http.post(serverurl, payload, {headers: options, withCredentials: true});
  }


  loadReport(token) {
    let options : HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('x-access-token', token);
    return this.http.get(environment.reportUrl,{headers: options, withCredentials: true});
  }

  loadProdotti(token){
    let options : HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('x-access-token', token);
    return this.http.get(environment.prodottiUrl,{headers: options, withCredentials: true});
  }

  loadValorizzazioni(token){
    let options : HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('x-access-token', token);
    return this.http.get(environment.valorizzazioniUrl,{headers: options, withCredentials: true});
  }

  /*
  loadProdottiByPPMID(token, ppmid){
    let options : HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('x-access-token', token);
    return this.http.get(environment.prodottibyppmidUrl + "/"+ ppmid,{headers: options, withCredentials: true});
  }

  loadValorizzazioniById(token, ppmid){
    let options : HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('x-access-token', token);
    return this.http.get(environment.valorizzazionibyId + "/"+ ppmid,{headers: options, withCredentials: true});
  }

  loadIfAuthed(token) {
    let options : HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('x-access-token', token)
    return this.http.get(environment.ifAuthedUrl, {headers:options, withCredentials: true});
  }

  loadIfAuthedMS(token) {
    let options : HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('x-access-token', token)
    return this.http.get(environment.ifAuthedUrlMS, {headers:options, withCredentials: true});
  }
  */
  storeNote(nota, token){
    //console.log("Eccomi");
    let options : HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('x-access-token', token)
    return this.http.post(environment.storeNote, nota, {headers:options, withCredentials: true});
  }

  getNotes(ppmid, token){
  let options : HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('x-access-token', token)
    return this.http.get(environment.notesUrl + "?ppmid=" + ppmid, {headers:options, withCredentials: true});
  }

  deleteNote(id, token){
    let options : HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('x-access-token', token)
    return this.http.get(environment.deleteNote + "?id=" + id, {headers:options, withCredentials: true});
  }

  editGenerale(ID, token){
    let options : HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('x-access-token', token)
      return this.http.get(environment.editGenerale + "?ID=" + ID, {headers:options, withCredentials: true});
    }



  signOut(): void {
    this.authService.signOut();
    //this.router.navigate(['/splash']);
  }

  notExpired(): boolean {
    if (localStorage.getItem('token') !== null){
      return true;
    }
    return false;
  }

}
