import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GoogleLoginProvider, SocialAuthService } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import { Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { animate, state, style, transition, trigger } from '@angular/animations';



@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.css'],
  animations:[
    trigger('opCl',[
      state('open',style({
        height: '100%'
      })),
      state('close',style({
        height:'0%',
        visibility: 'hidden'
      })),
      transition('open=> close', [
        animate('1.5s')
      ]),
      transition('close => open', [
        animate('1.5s')
      ])
    ])
  ]
})
export class SplashScreenComponent implements OnInit {
  //@Output() loggedEvent: EventEmitter<any>= new EventEmitter();
  @Output() onShowLoginComponent: EventEmitter<any>= new EventEmitter();
  user: SocialUser;
  loggedIn: boolean;
  isOp = true;

  constructor(private auth: AuthService, private authService: SocialAuthService, private router:Router) { }

  ngOnInit(): void {

     setTimeout(()=>{
      this.isOp = !this.isOp
      setTimeout(()=>{
        this.showLoginComponent();
      },2000)
    })

  }

 showLoginComponent(){
    this.onShowLoginComponent.emit(this.user);
  }

  /* signInWithGoogle(): void {
    console.log("User logged", this.user);
    this.loggedEvent.emit(this.user);

  } */

  //signInWithGoogle(user): void {
    //this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    //this.loggedEvent.emit(user);
  //}

  /* signOut(): void {
    this.auth.signOut();
  } */

  /* onChangeInHome(splash){
    if(splash < this.oldIndexSlide){
      this.onTerminateSplash.emit();
      this.router.navigate(['/home']);
    }
  }
 */

}
