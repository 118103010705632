<div style="width: 100%; background-color: rgba(255, 255, 255, 0.1);">
  <div style="width: 100%; background-color: rgba(255, 255, 255, 0.1);">

    <img [@opCl]="isOp? 'open':'close'" class="background-img" width="100%"  src="assets/img/splash.png" style="position: absolute; top:0; left:0">

    <img [@opCl]="isOp? 'open':'close'" style="position: absolute; margin: 0% 25%; margin-bottom: 10%;" width="50%" src="assets/img/logoTim.svg">
  </div>
</div>


