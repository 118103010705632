import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';
import {HttpClientModule} from '@angular/common/http';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {  GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { HomepageComponent } from './components/homepage/homepage.component';
import {MatIconModule} from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { ReleaseComponent } from './components/release/release.component';
import { ProdottoComponent } from './components/prodotto/prodotto.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FormsModule } from '@angular/forms';
import {MatSidenavModule} from '@angular/material/sidenav';
import { LoginComponent } from './components/login/login.component';
import {MatListModule} from '@angular/material/list';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {MatExpansionModule} from '@angular/material/expansion';
import {TextFieldModule} from '@angular/cdk/text-field';
import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule} from '@angular/material/dialog';
import{IPublicClientApplication, PublicClientApplication} from '@azure/msal-browser';
import { DialogComponent } from './components/dialog/dialog.component';
import { ValorizzazioneComponent } from './components/valorizzazione/valorizzazione.component';
import { MsalModule, MsalService, MSAL_INSTANCE } from '@azure/msal-angular';

export function MSALInstanceFactory(): IPublicClientApplication{
  return new PublicClientApplication({
    auth:{
      //clientId: '558fb454-d8f3-4376-9a0e-b95b4cdba81d',  //siliconvalle
      //authority:'https://login.microsoftonline.com/f50602f4-3420-47d4-82d8-50f51b577c09/', //siliconvalle
      clientId:'78f95749-d500-40be-8a15-d1dd1fabb851', //TIM - sdpomniwatchadmin
      authority:'https://login.microsoftonline.com/6815f468-021c-48f2-a6b2-d65c8e979dfb/', //TIM-tenant
      redirectUri: environment.redirectUriMs
      //redirectUri:'https://www.omniwatchprojects.it'
    }
  })
}

@NgModule({
  declarations: [
    AppComponent,
    SplashScreenComponent,
    HomepageComponent,
    ReleaseComponent,
    ProdottoComponent,
    LoginComponent,
    DialogComponent,
    ValorizzazioneComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MsalModule,
    SocialLoginModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatDialogModule,
    MatExpansionModule,
    HttpClientModule,
    MatIconModule,
    MatToolbarModule,
    MatSelectModule,
    TextFieldModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatButtonModule,
    Ng2SearchPipeModule,
    FormsModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
      registrationStrategy: "registerImmediately"
  })
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '139707677183-5iqp27lusvaotqs7ouipcht1b3l7u10s.apps.googleusercontent.com'
            )
          }
        ]
      } as SocialAuthServiceConfig,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    MsalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
