import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { SocialUser } from 'angularx-social-login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @Output() onLoginEventGoogle: EventEmitter<any>= new EventEmitter();
  @Output() onLoginEventMicrosoft: EventEmitter<any>= new EventEmitter();

  @Input() showMsg: string;
  user: SocialUser;
  deferredPrompt: any;
  showButton:boolean;

  constructor() { }
  @HostListener('window:beforeinstallprompt', ['$event'])
onbeforeinstallprompt(e) {
  //console.log(e);
  //e.preventDefault();
  this.deferredPrompt = e;
  this.showButton = true;
}


  addToHomeScreen(){
    //console.log("hello")
   this.showButton = false;
   if(typeof(this.deferredPrompt)!=='undefined'){
     this.deferredPrompt.prompt();
   }

   this.deferredPrompt.userChoice
   .then((choiceResult)=>{
     if(choiceResult.outcome === 'accepted'){
       //console.log('User accepted the A2HS prompt');
     }else{
       //console.log('User dismissed the A2HS prompt');
     }
     this.deferredPrompt = null;
   })}



  signInWithGoogle(user): void {
    //console.log("User logged", this.user);
    this.onLoginEventGoogle.emit(user);
   // console.log("*****UTENTE*****", this.user);

  }

  signInWithMicrosoft(user): void{
    this.onLoginEventMicrosoft.emit(user);
  }


  ngOnInit(): void {
  }

}
