import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { SocialUser } from "angularx-social-login";
import { SocialAuthService } from "angularx-social-login";
import { Card } from 'src/app/models/card';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Prodotti } from 'src/app/models/prodotti';
import { Valorizzazione } from 'src/app/models/valorizzazione';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit, OnChanges {
  @Input() cards: Card[];
  @Input() valorizzazioni: any;
  @Input() dictprodottibyid: any;
  @Input() dictprodottibyidv: any;
  @Input() dictprodottibyppmid: any;
  @Input() totaleAttivita;
  @Input() totaleMoi;
  @Input() imageSrcRed: boolean;
  @Input() imageSrcBlu: boolean;
  @Input() system:boolean;
  @Input() verdeOn: boolean;
  @Input() gialloOn: boolean;
  @Input() rossoOn: boolean;
  @Input() isLoadValorizzazioni: boolean;
  @Input() user: any;
  @Input() specificCard: Card[];
  @Input() specificRelease: Card[];
  @Input() specificMoney: Card[];
  @Input() specificColor: Card[];
  @Input() filterCO: boolean;
  @Input() filterBU: boolean;
  @Input() filterSemR: boolean;
  @Input() filterSemG: boolean;
  @Input() filterSemV: boolean;
  @Input() filterNoM: boolean;
  @Input() filterSiM: boolean;
  @Input() filterRefresh: boolean;
  @Input() filterKit: any;



  @Input() specificProdotto: Prodotti[];


  @Output() clickCard: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSpecificCard: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSpecificRelease: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSpecificAmbito: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSpecificMoney: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSpecificSemaphore: EventEmitter<any> = new EventEmitter<any>();
  @Output() onRefreshCards: EventEmitter<any> = new EventEmitter<any>();
  @Output() onlogOut: EventEmitter<any> = new EventEmitter<any>();
  @Output() onloggedIn: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFilteredSystem: EventEmitter<any> = new EventEmitter<any>();




  loggedIn: boolean;
  term;
  filterMetadata = { count: 0 };
  isVisible=false;
  isVisibleBlockButton=false;



  constructor( private authService: SocialAuthService, private router: Router,private http: HttpClient) {
     console.log(this.user)
  }

  filterSystems(specificSystem){
    this.onFilteredSystem.emit(specificSystem);
  }

  toggle(){
    this.isVisible =! this.isVisible;
  }

  toggleFooter(){
    this.isVisibleBlockButton =! this.isVisibleBlockButton;
  }

  setclassborder(id){
    switch (id) {
      case "Consumer":
        if (this.filterCO){ return("primary")}else{return("mydisabled")}
        break;
      case "Business & Top Client":
        if (this.filterBU){ return("primary")}else{return("mydisabled")}
        break;
      case "V":
        if (this.filterSemV){ return("mygreen")}else{return("mydisabled")}
        break;
      case "G":
        if (this.filterSemG){ return("myyellow")}else{return("mydisabled")}
        break;
      case "R":
        if (this.filterSemR){ return("myred")}else{return("mydisabled")}
        break;
      case "siM":
        if (this.filterSiM){ return("primary")}else{return("mydisabled")}
        break;
      case "noM":
        if (this.filterNoM){ return("primary")}else{return("mydisabled")}
        break;
      case "All":
        return("pushtrasparent");
        break;
      case "C1":
      case "C2":
      case "C3":
      case "C4":
      case "C5":
      case "C6":
      case "C7":
      case "C8":
      case "C9":
      case "C10":
      case "C11":
      case "C12":
        if (this.filterKit.includes(id)){ return("primary")}else{return("mydisabled")}
        break;
    }
  }

  getCardColour(value){
    if (value == 'R'){return("red")}
    if (value == 'V'){return("green")}
    if (value == 'G'){return("yellow")}
    return("grey")
  }

  goToEditCard(){
    this.onEdit.emit();
  }

  refreshCards(refreshedCards){
    this.onRefreshCards.emit(refreshedCards)
  }

  goRelease(card: Card){
    this.clickCard.emit(card);
  }

  chooseSpecificCard(specificCard){
    this.onSpecificCard.emit(specificCard);
  }

  chooseAmbitoRed(specificAmbito){
    this.onSpecificAmbito.emit(specificAmbito);
    /* this.imageSrcRed = !this.imageSrcRed; */

  }
  chooseAmbitoBlu(specificAmbito){
    this.onSpecificAmbito.emit(specificAmbito);
/*     this.imageSrcBlu = !this.imageSrcBlu; */
  }

  chooseRelease(specificRelease){
    this.onSpecificRelease.emit(specificRelease)
  }

  chooseMoney(specificMoney){
    this.onSpecificMoney.emit(specificMoney);
  }


  chooseSemaforoVerde(specificSemaphore){
    this.onSpecificSemaphore.emit(specificSemaphore);
  }
  chooseSemaforoGiallo(specificSemaphore){
    this.onSpecificSemaphore.emit(specificSemaphore);
  }
  chooseSemaforoRosso(specificSemaphore){
    this.onSpecificSemaphore.emit(specificSemaphore);
  }

  signOut(){
    this.onlogOut.emit();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    /* if( changes['specificCard'] && changes['specificCard'].previousValue != changes['specificCard'].currentValue ) {

      this.setCards(this.specificCard);
    }
    if( changes['specificRelease'] && changes['specificRelease'].previousValue != changes['specificRelease'].currentValue ) {

      this.setRelease(this.specificRelease);
    }
    if( changes['specificMoney'] && changes['specificMoney'].previousValue != changes['specificMoney'].currentValue ) {

      this.setMoney(this.specificMoney);
    }
    if( changes['specificColor'] && changes['specificColor'].previousValue != changes['specificColor'].currentValue ) {

      this.setColor(this.specificColor);
    } */

}

setTot(tmpTot:Card[]){
  this.cards=tmpTot;
}

setMoney(tmpMoney:Card[]){
  this.cards=tmpMoney;
}



setCards(tmpCard:Card[]){
  this.cards= tmpCard;
}

setRelease(tmpRelease:Card[]){
  this.cards=tmpRelease;
}

setColor(tmpColor: Card[]){
  this.cards=tmpColor;
}


  ngOnInit(): void {
  }

}
