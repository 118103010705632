import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { SocialUser } from "angularx-social-login";
import { SocialAuthService } from "angularx-social-login";
import { Prodotti } from 'src/app/models/prodotti';
import {Nota} from 'src/app/models/note';
import {MatAccordion} from '@angular/material/expansion';
import { NgForm } from '@angular/forms';
import { Card } from 'src/app/models/card';
import { AuthService } from 'src/app/service/auth.service';
import {MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Valorizzazione } from 'src/app/models/valorizzazione';


export enum AllValues {
  V = "V",
  G = "G",
  R = "R",
}


@Component({
  selector: 'app-release',
  templateUrl: './release.component.html',
  styleUrls: ['./release.component.css']
})
export class ReleaseComponent implements OnInit {
  step = 0;
  allValues = AllValues;
  /* myValue: AllValues = AllValues.V; */
  valueOptions = Object.keys(AllValues);
  CurrentDate=new Date();
  panelOpenState;
  Note=[];



  setStep(index: number) {
    this.step = index;
  }



  getCardColor(value){
    if (value === 'R'){return("red")}
    if (value === 'V'){return("green")}
    if (value === 'G'){return("yellow")}
    return("grey")
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  @Input() activeCard;
  @Input() activeProdotto;



  heigthChanged: boolean= false;
  @Input()Notes;
//@Input() activeProdotto;

  @Input() prodotti: Prodotti[];
  @Input() valorizzazioni: any;
  @Input() dictprodottibyid: any;
  @Input() dictprodottibyidv: any;
  @Input() dictprodottibyppmid: any;

  /* @Input() cards: Card[]; */

  @Output() OnClickProdotto: EventEmitter<any> = new EventEmitter<any>();
  @Output() OnClickVal: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickHome: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeGeneralita: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeFattibilita: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEditGeneralita: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEditFattibilita: EventEmitter<any> = new EventEmitter<any>();
  @Output() onReturnEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onResetForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSaveNote: EventEmitter<any> = new EventEmitter<any>();
  @Output() onRefreshNotes: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeleteNotes: EventEmitter<any> = new EventEmitter<any>();
  @Output() onOpenDialog: EventEmitter<any> = new EventEmitter<any>();



  @ViewChild(MatAccordion) accordion: MatAccordion;
  constructor( private authService: SocialAuthService, private auth: AuthService, private matDialog: MatDialog) {

  }



  loggedIn: boolean;
  @Input() inactive: boolean=true;
  @Input() stop: boolean=true;
  @Input() user: any;



  goProductDetails(prodotto: Prodotti){
    this.OnClickProdotto.emit(prodotto);
  }

  goValDetails(valore: Valorizzazione){
    this.OnClickVal.emit(valore);
  }

  returnHome(){
    this.clickHome.emit();
  }

  changeGenCard(editGeneralita: NgForm){
    this.onChangeGeneralita.emit(editGeneralita);
  }

  changeGeneralita(generalita){
    this.onChangeGeneralita.emit(generalita);
  }

  changeFattibilita(fattibilita){
    this.onChangeFattibilita.emit(fattibilita);
  }

  editGen(editedGen){
    this.onEditGeneralita.emit(editedGen);
  }

  editFat(editedFat){
    this.onEditFattibilita.emit(editedFat);
  }

  saveNote(savedNota: NgForm){
    this.onSaveNote.emit(savedNota);
  }

  resetForm(f:NgForm){
    this.onResetForm.emit(f);
  }

  returnEdit(f:NgForm){
    this.onReturnEdit.emit(f);
  }

  openDialog(){
    this.onOpenDialog.emit();
  }



  saveCard(savedCard: NgForm){
    let x=savedCard.value;
    x["ppmid"]=this.activeCard["Id"]
    x["data"]=new Date();
    x["id"]=Date.now().toString();
    x["autore"]=this.user.name;
    this.onSaveNote.emit(x);
    this.heigthChanged=false;

  }

  getValue(item: any){
    return(item)
  }

  setProdotti(tmpProdotti: Prodotti[]){
    this.prodotti=tmpProdotti;
  }

  setValorizzazioni(tmpValori: Valorizzazione[]){
    this.valorizzazioni=tmpValori;
  }

  openAdd(){
    this.heigthChanged = !this.heigthChanged;
    //console.log(this.heigthChanged)
  }

  deleteEvent(nota){
    this.onDeleteNotes.emit(nota);
  }

  signOut(){}

  numProdPrevisti(card){
    return( card["Fastdata"]+
            card["CRFastdata"]+
            card["DispositiveSPDX"]+
            card["CRDispositiveSPDX"]+
            card["InformativeSPDX"]+
            card["InfomativaSPDX"]+
            card["EventLayer"]+
            card["DispositivePT"]+
            card["InformativaPT"])
  }


  ngOnInit(): void {
    console.log('PPMID selezionato');
    console.log(this.activeCard["Id"]);    
    console.log('VALORIZZAZIONI della Release')
    console.log(this.valorizzazioni)
    console.log(this.valorizzazioni[this.activeCard["Id"]])
    console.log('PRODOTTI della Release')
    console.log(this.dictprodottibyppmid[this.activeCard["Id"]])


    this.onRefreshNotes.emit(this.activeCard["Id"]);
  }

}
