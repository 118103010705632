import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Prodotti } from 'src/app/models/prodotti';
import {MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-prodotto',
  templateUrl: './prodotto.component.html',
  styleUrls: ['./prodotto.component.css']
})
export class ProdottoComponent implements OnInit {
  @Input() prodotti: Prodotti[];
  @Input() activeProdotto;
  @Input() block: boolean=true;



  @Output() clickRelease: EventEmitter<any> = new EventEmitter();
  @Output() onChangeProdotto: EventEmitter<any> = new EventEmitter();
  @Output() onReturnEdit: EventEmitter<any> = new EventEmitter();
  @Output() onOpenDialog: EventEmitter<any> = new EventEmitter<any>();



  constructor() { }

  changeProdotto(prodotto){
    this.onChangeProdotto.emit(prodotto);
  }

  signOut(){}

  returnRelease(){
    this.clickRelease.emit();
  }

  returnEdit(f:NgForm){
    this.onReturnEdit.emit(f);
  }

  openDialog(){
    this.onOpenDialog.emit();
  }

  ngOnInit(): void {
  }

}
