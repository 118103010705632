<mat-sidenav-container style="height: 100%;" >
  <mat-sidenav-content>
    <div class="max-contenitore"  style="position: fixed; width: 100%; z-index: 99;">
      <mat-toolbar style="background: white; width: 100%;height: 40vw; box-shadow: 0px 10px 12px #e4e3e3; border-bottom-left-radius: 25px; border-bottom-right-radius: 25px;" class="position-fixed">
        <span style="display: block; line-height: 4vw;">
          <p id="release-card">{{activeCard.generale.ReleaseDesiderata}}</p>
          <p id="id-card">{{activeCard?.Id}}</p>
          <p id="cod-card">{{activeCard.generale.CodiceAGESP}}</p>
          <div class="description"><small>{{activeCard.generale.Requisito}}</small></div>
        </span>
    </mat-toolbar>
      </div>
    <div class="content">
      <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel style="display: block; position: relative; width: 95%; margin: 5vw auto; box-shadow: none; background-color: #F0F4F8; border-radius: 2%;" [expanded]="step === 0" (opened)="setStep(0)" >
          <mat-expansion-panel-header style="background-color: #F0F4F8 ;">
            <mat-panel-title>
              <h3 id="generale"> <strong>GENERALE</strong> </h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <form class="prima-parte"  #f="ngForm" (submit)="editGen(f)">
            <mat-form-field class="example-full-width" >
              <mat-label [ngStyle]="{'color': inactive === true ? 'rgba(0,0,0,.54)' : '#0032a1' }">Release Desiderata:</mat-label>
              <input [ngModel]="activeCard.generale.ReleaseDesiderata  === '' ? '---': activeCard.generale.ReleaseDesiderata" name="release" matInput placeholder="" value="{{activeCard.generale.Release_Desiderata}}" style="color: #000;" [disabled]="inactive">
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label [ngStyle]="{'color': inactive === true ? 'rgba(0,0,0,.54)' : '#0032a1' }">Data Desiderata:</mat-label>
              <input [ngModel]="activeCard.generale.DataDesiderata === '' ? '---': activeCard.generale.DataDesiderata" name="data" matInput placeholder="" value="{{activeCard.generale.Data_Desiderata}}" style="color: #000;" [disabled]="inactive">
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label [ngStyle]="{'color': inactive === true ? 'rgba(0,0,0,.54)' : '#0032a1' }">Data Produzione Stimata:</mat-label>
              <input [ngModel]="activeCard.generale.DataProduzioneStimata === '' ? '---': activeCard.generale.DataProduzioneStimata" name="produzione" matInput placeholder="" value="{{activeCard.generale.Data_Produzione_Stimata}}" style="color: #000;" [disabled]="inactive">
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label [ngStyle]="{'color': inactive === true ? 'rgba(0,0,0,.54)' : '#0032a1' }">Pianificazione Attuale Release:</mat-label>
              <input [ngModel]="activeCard.fattibilita.ReleasePianificata === '' ? '---': activeCard.generale.ReleasePianificata" name="pianificazione" matInput placeholder="" value="{{activeCard.fattibilita.PianificazioneAttuale}}" style="color: #000;" [disabled]="inactive">
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label [ngStyle]="{'color': inactive === true ? 'rgba(0,0,0,.54)' : '#0032a1' }">Business Plan:</mat-label>
              <input [ngModel]="activeCard.generale.BusinessPlan === '' ? '---': activeCard.generale.BusinessPlan" name="business" matInput placeholder="" value="{{activeCard.generale.BusinessPlan}}" style="color: #000;" [disabled]="inactive">
            </mat-form-field>
            <div class="pulsanti">
               <button *ngIf="!inactive" style="margin-top: 5vw; float: left; margin-bottom: 5vw" mat-raised-button type="submit" >Salva e Modifica</button>
                <button *ngIf="!inactive" style="margin-top: 5vw; float: right;margin-bottom: 5vw" mat-raised-button color="warn" (click)="returnEdit(f)">Annulla</button>
            </div>
          </form>
          <div id="edit">
            <i id="modifica" class="fa fa-edit" (click)="openDialog()"></i>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel style="display: block; position: relative;  width: 95%; margin: 5vw auto; box-shadow: none; background-color: #F0F4F8;"  [expanded]="step === 0" (opened)="setStep(0)">
          <mat-expansion-panel-header style="background-color: #F0F4F8; ; margin-top: 1px;">
            <mat-panel-title>
              <h3 id="fattibilita" > <strong>FATTIBILITA'</strong> </h3> <div id="circle1" [ngStyle]="{'background-color': activeCard.fattibilita.Semaforo === 'V'? 'green': 'red'}"><p style="display: none;">{{activeCard.fattibilita.Semaforo}}</p></div>
            </mat-panel-title>
          </mat-expansion-panel-header>
              <form class="seconda-parte"  #f="ngForm" (submit)="editFat(f)">
                <mat-form-field class="example-full-width">
                  <mat-label [ngStyle]="{'color': stop === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" id="label-inp">OwnerSDP:</mat-label>
                  <input  [ngModel]="activeCard.fattibilita?.OwnerSDP === '' ? '---': activeCard.fattibilita.OwnerSDP" name="owner" id="valore-ritorno" matInput placeholder="" value="{{activeCard.fattibilita?.OwnerSDP}}" style="color: #000;" [disabled]="stop">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label [ngStyle]="{'color': stop === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" id="label-inp">Data di inserimento:</mat-label>
                  <input [ngModel]="activeCard.fattibilita?.DataInserimento === '' ? '---': activeCard.fattibilita.DataInserimento" name="inserimento" id="valore-ritorno" matInput placeholder="" value="{{activeCard.fattibilita?.DataInserimento}}" style="color: #000;" [disabled]="stop">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label [ngStyle]="{'color': stop === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" id="label-inp">FastData:</mat-label>
                  <input [ngModel]="activeCard.fattibilita?.Fastdata === '' ? '---': activeCard.fattibilita.Fastdata" name="fastData"  id="valore-ritorno" matInput placeholder="" value="{{activeCard.fattibilita?.Fastdata}}" style="color: #000;" [disabled]="stop">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label [ngStyle]="{'color': stop === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" id="label-inp">CR FastData:</mat-label>
                  <input [ngModel]="activeCard.fattibilita?.CRFastdata === '' ? '---': activeCard.fattibilita.CRFastdata"  name="Cr_fastData"  id="valore-ritorno" matInput placeholder="" value="{{activeCard.fattibilita?.CRFastdata}}" style="color: #000;" [disabled]="stop">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label [ngStyle]="{'color': stop === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" id="label-inp">Dispositive SPDX:</mat-label>
                  <input [ngModel]="activeCard.fattibilita?.DispositiveSPDX === '' ? '---': activeCard.fattibilita.DispositiveSPDX" name="dispositiveSPDX"  id="valore-ritorno" matInput placeholder="" value="{{activeCard.fattibilita?.DispositiveSPDX}}" style="color: #000;" [disabled]="stop">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label [ngStyle]="{'color': stop === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" id="label-inp">CR Dispositiva SPDX:</mat-label>
                  <input [ngModel]="activeCard.fattibilita?.CRDispositiveSPDX === '' ? '---': activeCard.fattibilita.CRDispositiveSPDX" name="CRdispositiveSPDX"  id="valore-ritorno" matInput placeholder="" value="{{activeCard.fattibilita?.CRDispositiveSPDX}}" style="color: #000;" [disabled]="stop">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label [ngStyle]="{'color': stop === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" id="label-inp">Informative SPDX:</mat-label>
                  <input [ngModel]="activeCard.fattibilita?.InformativeSPDX === '' ? '---': activeCard.fattibilita.InformativeSPDX" name="InformativeSPDX"  id="valore-ritorno" matInput placeholder="" value="{{activeCard.fattibilita?.InformativeSPDX}}" style="color: #000;" [disabled]="stop">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label [ngStyle]="{'color': stop === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" id="label-inp">CR Informative SPDX:</mat-label>
                  <input [ngModel]="activeCard.fattibilita?.CRInfomativaSPDX === '' ? '---': activeCard.fattibilita.CRInfomativaSPDX" name="CRinformativeSPDX"  id="valore-ritorno" matInput placeholder="" value="{{activeCard.fattibilita?.CRInfomativaSPDX}}" style="color: #000;" [disabled]="stop">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label [ngStyle]="{'color': stop === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" id="label-inp">Informativa PT:</mat-label>
                  <input [ngModel]="activeCard.fattibilita?.InformativaPT === '' ? '---': activeCard.fattibilita.InformativaPT" name="informativaPT"  id="valore-ritorno" matInput placeholder="" value="{{activeCard.fattibilita?.InformativaPT}}" style="color: #000;" [disabled]="stop">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label [ngStyle]="{'color': stop === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" id="label-inp">Dispositive PT:</mat-label>
                  <input [ngModel]="activeCard.fattibilita?.DispositivePT === '' ? '---': activeCard.fattibilita.DispositivePT" name="dispositivePT"  id="valore-ritorno" matInput placeholder="" value="{{activeCard.fattibilita?.DispositivePT}}" style="color: #000;" [disabled]="stop">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label [ngStyle]="{'color': stop === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" id="label-inp">Impatto Documentale:</mat-label>
                  <input [ngModel]="activeCard.fattibilita?.ImpattoDocumentale === '' ? '---': activeCard.fattibilita.ImpattoDocumentale" name="ImpattoDocumentale"  id="valore-ritorno" matInput placeholder="" value="{{activeCard.fattibilita?.ImpattoDocumentale}}" style="color: #000;" [disabled]="stop">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label [ngStyle]="{'color': stop === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" id="label-inp">Supporto Analisi:</mat-label>
                  <input [ngModel]="activeCard.fattibilita?.SupportoAnalisi === '' ? '---': activeCard.fattibilita.SupportoAnalisi" name="supportoAnalisi"  id="valore-ritorno" matInput placeholder=""  value="{{activeCard.fattibilita.SupportoAnalisi}}" style="color: #000;" [disabled]="stop">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label [ngStyle]="{'color': stop === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" id="label-inp">Solo Collaudo:</mat-label>
                  <input [ngModel]="activeCard.fattibilita?.SoloCollaudo === '' ? '---': activeCard.fattibilita.SoloCollaudo" name="Collaudo"  id="valore-ritorno" matInput placeholder="" value="{{activeCard.fattibilita?.SoloCollaudo}}" style="color: #000;" [disabled]="stop">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label [ngStyle]="{'color': stop === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" id="label-inp">Totali Moi:</mat-label>
                  <input [ngModel]="activeCard.fattibilita?.MOI === '' ? '---': activeCard.fattibilita.MOI" name="moi"  id="valore-ritorno" matInput placeholder="" value="{{activeCard.fattibilita?.MOI}} " style="color: #000;" [disabled]="stop">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label [ngStyle]="{'color': stop === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" id="label-inp">Totali Moi:</mat-label>
                  <input [ngModel]="activeCard.fattibilita?.MOS === '' ? '---': activeCard.fattibilita.MOS" name="mos"  id="valore-ritorno" matInput placeholder="" value="{{activeCard.fattibilita?.MOS}} " style="color: #000;" [disabled]="stop">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label [ngStyle]="{'color': stop === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" id="label-inp">Totale &euro;:</mat-label>
                  <input [ngModel]="activeCard.fattibilita?.Totale === '' ? '---': activeCard.fattibilita.Totale" name="totale"  id="valore-ritorno" matInput placeholder="" value="{{activeCard.fattibilita?.Totale}}" style="color: #000;" [disabled]="stop">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label [ngStyle]="{'color': stop === true ? 'rgba(0,0,0,.54)' : '#0032a1' }" id="label-note">Note:</mat-label>
                  <textarea style="overflow: auto; font-size: 12px; resize: none; color: #000; width: 100%; text-align: justify;" [ngModel]="activeCard.fattibilita?.Note === '' ? '---': activeCard.fattibilita.Note"
                  matInput placeholder="" name="note"
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="5" [disabled]="stop"></textarea>
                </mat-form-field>
                <div class="pulsanti">
                  <button *ngIf="!stop" style="margin-top: 5vw; float: left; margin-bottom: 5vw" mat-raised-button type="submit" >Salva e Modifica</button>
                <button *ngIf="!stop" style="margin-top: 5vw; float: right; margin-bottom: 5vw" mat-raised-button color="warn" (click)="returnEdit(f)">Annulla</button>
                </div>
              </form>
            <div id="editFatt">
              <i id="modifica" class="fa fa-edit" (click)="openDialog()"></i>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel style="display: block; position: relative;  width: 95%; margin: 5vw auto; box-shadow: none; background-color: #F0F4F8;"  [expanded]="step === 0" (opened)="setStep(0)">
          <mat-expansion-panel-header style="background-color: #F0F4F8; margin-top: 1px;">
            <mat-panel-title>
              <h3 id="prodotti"> <strong>VALORIZZAZIONI</strong> </h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="contenitore-bottoni" *ngFor="let valori of valorizzazioni[activeCard.Id]">
                <button class="btn btn-outline-dark bottoni" mat-raised-button (click)="goValDetails(valori)">
                  <div style="display: flex;">
                    <div style="text-align: left; width: 40%;">
                      <div id="versione" style="font-size:14px; resize:none;"><strong>{{valori?.CodiceRichiestaValorizzazione}}</strong></div>
                      <div id="versione" style="font-size:14px; resize:none;">{{valori?.CODICE1}}</div>
                    </div>
                    <div style="text-align: right; width: 40%; margin-left: 3em;">
                      <div id="servizio" style="font-size: 12px; resize: none;"><strong>{{valori?.NomeFornitore | slice: 12 }}</strong></div>
                      <div id="servizio" style="font-size: 12px; resize: none;">{{valori?.STATOPAPERLESS1}}</div>
                    </div>
                  </div>
                  <div style="float: right; font-style: italic; font-size: 12px;">Valore Totale: <span>{{valori?.ValoreTotale}}</span></div>
                  <div *ngIf="valori?.CODICE1" class="barra"></div>
                </button>
              </div>
        </mat-expansion-panel>
        
        <mat-expansion-panel style="display: block; position: relative;  width: 95%; margin: 5vw auto; box-shadow: none; background-color: #F0F4F8;"  [expanded]="step === 0" (opened)="setStep(0)">
          <mat-expansion-panel-header style="background-color: #F0F4F8; margin-top: 1px;">
            <mat-panel-title>
              <h3 id="prodotti"> <strong>PRODOTTI</strong> </h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
              <div class="contenitore-bottoni" *ngFor="let prod of dictprodottibyppmid[activeCard.Id]">
                <button class="btn btn-outline-dark bottoni" mat-raised-button (click)="goProductDetails(prod)"> <span id="versione">{{prod["ID-Versione"]}}</span> <span id="servizio">({{prod.TipoServizio}})</span> </button>
              </div>
        </mat-expansion-panel>
        <mat-expansion-panel style="display: block; position: relative; margin-bottom: 15vw; box-shadow: none; width: 95%; margin: 5vw auto;  background-color: #F0F4F8;"  [expanded]="step === 0" (opened)="setStep(0)">
          <mat-expansion-panel-header style="background-color: #F0F4F8; margin-top: 1px;">
            <mat-panel-title>
              <h3 id="note"> <strong>NOTE</strong> </h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="aggiungi-note" >
            <mat-icon style="float: right; margin-top: 2vw; margin-right: 5vw;" *ngIf="!heigthChanged" (click)="openAdd()">add</mat-icon>
            <mat-icon style="float: right; margin-top: 2vw; margin-right: 5vw;" *ngIf="heigthChanged" (click)="openAdd()">remove</mat-icon>
            <div *ngIf="heigthChanged">
              <form class="prima-parte" #f="ngForm" (submit)="saveCard(f)">
                <h4 ><strong style="color: #0032a1;">AGGIUNGI UN'ATTIVITA'</strong></h4>
                      <hr>
                <mat-form-field class="example-full-width">
                  <mat-label>Oggetto:</mat-label>
                  <input [ngModel]="Note.oggetto" matInput placeholder="" type="text" name="oggetto">
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Seleziona la criticità:</mat-label>
                  <mat-select [ngModel]="myValue" name="criticita">
                    <mat-option *ngFor="let o of valueOptions" value="{{allValues[o]}}">
                      <span id="circle" [style.backgroundColor]="getCardColor(allValues[o])">
                        <span id="circle">{{allValues[o]}} </span>
                      </span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field >
                  <mat-label>Testo:</mat-label>
                  <textarea
                  [ngModel]="Note.testo" matInput placeholder="" name="testo"
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="5"></textarea>
                </mat-form-field>
                <div class="form-note">
                  <button style="margin-top: 5vw; display: block; float: left;" mat-raised-button type="submit">Salva e Aggiungi</button>
                  <button style="margin-top: 5vw; display: block; float: right;" mat-raised-button color="warn" type="button" (click)="resetForm(f)" >Annulla</button>
                </div>
              </form>
            </div>
          </div>
          <div class="contenitore-note" >
            <div *ngFor="let nota of Notes">
              <mat-card class="card-note">
                <span id="circle_note" [style.backgroundColor]="getCardColor(nota?.criticita)">
                  <p style="display: none;">{{nota?.criticita}}</p>
                </span>
                <span id="data">{{nota.data | date:'dd-MM-yy  HH:mm:ss'}}</span>
                <span id="autore">{{nota.autore}}</span>
                <div class="contenitore" style="display: inline-flex; width: 100%;">
                    <div id="testo">
                  <div>
                    <strong>
                      {{nota.oggetto}}
                    </strong>
                  </div>
                  <div>
                    {{nota.testo}}
                  </div>
                </div>
                <i class="fa fa-trash cestino" aria-hidden="true" (click)="deleteEvent(nota)"></i>
                </div>
              </mat-card>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <footer>
      <div id="back">
        <span class="material-icons return" (click)="returnHome()">
          west
        </span>
      </div>
    </footer>
  </mat-sidenav-content>
</mat-sidenav-container>
