<app-splash-screen *ngIf="splashscreen"
                    (onShowLoginComponent)="showFormLogin()"
                    >
</app-splash-screen>

<app-login *ngIf="login"
          (onLoginEventGoogle)="onLoginEventGoogle($event)"
          (onLoginEventMicrosoft)="loginEventMicrosoft($event)"
          [showMsg]="showMsg">
</app-login>

<app-homepage *ngIf="homepage"
              (clickCard)="goCardDetails($event)"
              (onSpecificCard)="chooseCard($event)"
              (onSpecificAmbito)="chooseAmbito($event)"
              (onSpecificRelease)="chooseRelease($event)"
              (onSpecificMoney)="chooseMoney($event)"
              (onSpecificSemaphore)="chooseSemaphore($event)"
              (onRefreshCards)="refreshReport()"
              (onEdit)="addCards($event)"
              (onFilteredSystem)="chooseSystem($event)"
              (onlogOut)="logOutToHome()"
              [imageSrcRed]="imageSrcRed"
              [imageSrcBlu]="imageSrcBlu"
              [system]="filterSystem"
              [verdeOn]="verdeOn"
              [gialloOn]="gialloOn"
              [rossoOn]="rossoOn"
              [isLoadValorizzazioni]="isLoadValorizzazioni"
              [(user)]="user"
              [filterCO]="filterCO"
              [filterBU]="filterBU"
              [filterSemR]="filterSemR"
              [filterSemG]="filterSemG"
              [filterSemV]="filterSemV"
              [filterNoM]="filterNoM"
              [filterSiM]="filterSiM"
              [filterKit]="filterKit"
              [filterRefresh]="filterRefresh"
              [specificCard]="lista1"
              [specificRelease]="lista2"
              [specificMoney]="lista3"
              [specificColor]="lista4"
              [cards]="listaUtile"
              [valorizzazioni]="dictvalorizzazioni"
              [dictprodottibyid]="dictprodottibyid"
              [dictprodottibyidv]="dictprodottibyidv"
              [dictprodottibyppmid]="dictprodottibyppmid"
              [totaleAttivita]="totaleAttivita"
              [totaleMoi]="totaleMoi"
              [totaleMOI]="totaleMOI">
</app-homepage>

<app-release *ngIf="release"
              (OnClickProdotto)="goProdottoDetails($event)"
              (OnClickVal)="goValDetails($event)"
              (clickHome)="goHome()"
              (onChangeGeneralita)="changeGen($event)"
              (onOpenDialog)="openPrompt()"
              (onChangeFattibilita)="changeFatt($event)"
              (onEditGeneralita)="editGeneralita($event)"
              (onEditFattibilita)="editFattibilita($event)"
              (onReturnEdit)="resetEdit($event)"
              (onSaveNote)="addNote($event)"
              (onResetForm)="reset($event)"
              (onRefreshNotes)="refreshNotes($event)"
              (onDeleteNotes)="deleteNota($event)"
              [(user)]="user"
              [activeCard]="activeCard"
              [prodotti]="listaprodotti"
              [valorizzazioni]="dictvalorizzazioni"
              [dictprodottibyid]="dictprodottibyid"
              [dictprodottibyidv]="dictprodottibyidv"
              [dictprodottibyppmid]="dictprodottibyppmid"
              [Notes]="notes"
              [inactive]="inactive"
              [stop]="stop"
              >
</app-release><!--  [cards]="datiCard" -->

<app-prodotto *ngIf="prodotto"
                (clickRelease)="goRelease()"
                (onOpenDialog)="openPrompt()"
                [activeProdotto]="activeProdotto"
                (onChangeProdotto)="changeProd($event)"
                (onEditProdotto)="editProdotto($event)"
                (onReturnEdit)="resetEdit($event)"
                [block]="block">
</app-prodotto>

<app-valorizzazione *ngIf="valorizzazione"
                      [activeValore]="activeValore"
                      (clickRelease)="goRelease()"
                      (onOpenDialog)="openPrompt()"
                      (onEditProdotto)="editProdotto($event)"
                      (onReturnEdit)="resetEdit($event)">
</app-valorizzazione>
