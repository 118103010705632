import { HttpClient } from '@angular/common/http';
import { Component, HostListener, Input} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { GoogleLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import { Card } from './models/card';
import { AuthService } from './service/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from './components/dialog/dialog.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Prodotti } from './models/prodotti';
import { animate, style, transition, trigger } from '@angular/animations';
import { Valorizzazione } from './models/valorizzazione';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-common';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'Omniwatch';
  splashscreen:boolean;
  homepage:boolean;
  release:boolean;
  prodotto:boolean;
  login:boolean;
  valorizzazione:boolean;
  addCard:boolean;
  activeCard;
  activeProdotto;
  activeValore;
  user:any;
  loggedIn: boolean;
  inactive:boolean= true;
  stop:boolean= true;
  block:boolean= true;
  notes:any;
  utente;
  @Input() showMsg;
  defaultValue= "-";
  totaleAttivita= 0;
  totaleMoi= 0;

  datiCard: Card[] = []
  listaoriginale: any=[];
  listaprodotti: any=[];
  listavalorizzazioni: any= [];
  dictvalorizzazioni: any=[];
  datiProdotti:any=[];
  dictprodottibyid:any={};
  dictprodottibyidv:any={};
  dictprodottibyppmid:any={};
  lista1= this.datiCard;
  lista2=this.datiCard;
  lista3=this.datiCard;
  lista4=this.datiCard;
  lista5=this.datiCard;
  lista6=this.datiCard;
  listaUtile:any =[];

  filterCO:   boolean;
  filterBU:   boolean;
  filterSemR: boolean;
  filterSemG: boolean;
  filterSemV: boolean;
  filterNoM:  boolean;
  filterSiM:  boolean;
  //filterSystem: boolean;
  filterRefresh:  boolean;
  filterKit:  any;
  filtroSistema: any=[];
  deferredPrompt: any;
  showButton = false;
  @Input() imageSrcRed= true;
  @Input() imageSrcBlu = true;
  @Input() filterSystem:string;
  @Input() verdeOn=true;
  @Input() gialloOn=true;
  @Input() rossoOn=true;
  @Input() isLoadValorizzazioni=false;
  @Input() esito;

  constructor(public router: Router, private auth: AuthService,public authService:SocialAuthService, private http: HttpClient,public dialog: MatDialog, private msalService: MsalService) {
    this.homepage=false;
    this.splashscreen=true;
    this.valorizzazione=false;
    this.release=false;
    this.prodotto=false;
    this.filterCO=false;
    this.filterBU=false;
    this.filterSemR=false;
    this.filterSemG=false;
    this.filterSemV=false;
    this.filterNoM=false;
    this.filterSiM=false;
    this.filterSystem;
    this.filterRefresh=false;
    this.filterKit=[];
    this.activeCard={};
    this.activeProdotto={}
    this.activeValore={}
}

@HostListener('window:beforeinstallprompt', ['$event'])
onbeforeinstallprompt(e) {
  /* console.log(e); */
  //e.preventDefault();
  this.deferredPrompt = e;
  this.showButton = true;
}

  downloadData(betoken) {
    this.auth.loadValorizzazioni(betoken).subscribe(argument=>{
      this.elaboraValorizzazioni(argument);
      this.auth.loadProdotti(betoken).subscribe(argument=>{
        this.elaboraProdotti(argument); 
        this.auth.loadReport(betoken).subscribe(arg => {
          if(arg['status']!=200){
            sessionStorage.clear();
            console.log('Utente non autorizzato. si rimanda al login');
            console.log(arg['esito'])
            this.esito=arg['esito']
            this.homepage=false;
            this.splashscreen=false;
            this.release=false;
            this.prodotto=false;
            this.valorizzazione=false;
            this.login=true;
            this.showMsg= "Il tuo account non è autorizzato ad eseguire l'accesso";
          } 
          this.elaboraRispostaReport(arg);
        });
      });  
    });
  }

  refreshReport(betoken) {
    this.auth.loadReport(betoken).subscribe(arg => this.elaboraRispostaReport(arg));
  }


  ifOrdinato (ppmid)  {
    let counter = 0;
    //console.log(ppmid)
    if (ppmid in this.dictvalorizzazioni) {
      const listavalorizzazioni=this.dictvalorizzazioni[ppmid];
      //console.log(listavalorizzazioni);
      for (let x=0; x<listavalorizzazioni.length; x++) {
          //console.log(listavalorizzazioni[x])
          if (listavalorizzazioni[x] && (listavalorizzazioni[x].hasOwnProperty('CODICE1')) && (listavalorizzazioni[x]['CODICE1'] != 0)) {
            counter ++;
          }
      }
    }
    //console.log(counter)
    return(counter)
  }


  elaboraRispostaReport(x) {   
    this.datiCard=x;
    this.listaoriginale=x["data"];
    this.listaoriginale.map((obj)=>{
      obj.fattibilita['Totale'] = obj.fattibilita['Totale€'];
      
      delete obj.fattibilita['Totale€'];
      //delete obj.fattibilita['"EventLayer "'];
      obj["ordinato"]= this.ifOrdinato(obj.Id);
      return obj
    })
    //console.log(this.listaoriginale)
    this.ricostruisciListaItems();
  }

  elaboraRispostaProdotti(x) {
    this.datiCard=x;
    this.listaprodotti=x["data"];
    this.listaprodotti.map(function(obj){
      obj['IDVersione']=obj['ID-Versione'];
      delete obj['ID-Versione'];
      return obj;
    })
    //this.ricostruisciListaProd();
  }

  elaboraValorizzazioni(x){
    this.datiCard=x;
    this.dictvalorizzazioni={}
    x['data'].forEach((elem)=>{
      if(elem['ID'] in this.dictvalorizzazioni ){
        this.dictvalorizzazioni[elem['ID']].push(elem);
      }else{
        this.dictvalorizzazioni[elem['ID']]=[];
        this.dictvalorizzazioni[elem['ID']].push(elem);
      }
    })
  }

  elaboraProdotti(x){
      this.datiProdotti=x;
      console.log(this.datiProdotti);
      this.dictprodottibyid={}
      this.dictprodottibyidv={}
      this.dictprodottibyppmid={}
      /* console.log('Eccomi')
      console.log(this.dictvalorizzazioni); */
      //this.ricostruisciListaVal();
      x['data'].forEach((elem)=>{
        if(elem['ID'] in this.dictprodottibyid ){
          this.dictprodottibyid[elem['ID']].push(elem);
        }else{
          this.dictprodottibyid[elem['ID']]=[];
          this.dictprodottibyid[elem['ID']].push(elem);
        }
        if(elem['ID-Versione'] in this.dictprodottibyid ){
          this.dictprodottibyidv[elem['ID-Versione']].push(elem);
        }else{
          this.dictprodottibyidv[elem['ID-Versione']]=[];
          this.dictprodottibyidv[elem['ID-Versione']].push(elem);
        }
        if(elem['PPMID'] in this.dictprodottibyppmid ){
          this.dictprodottibyppmid[elem['PPMID']].push(elem);
        }else{
          this.dictprodottibyppmid[elem['PPMID']]=[];
          this.dictprodottibyppmid[elem['PPMID']].push(elem);
        }
      })
      console.log(this.dictprodottibyidv);
      console.log(this.dictprodottibyppmid);
  }




  addCards(){
  this.splashscreen=false;
  this.homepage=false;
  this.release=false;
  this.prodotto=false;
  this.login=false;
  this.addCard=true;
  this.valorizzazione=false;
  }


  goCardDetails(card: Card, prodotti: Prodotti, valori: Valorizzazione){
    this.homepage=false;
    this.release=true;
    this.splashscreen=false;
    this.valorizzazione=false;
    this.prodotto=false;
    this.addCard=false;
    this.activeCard=card;
    //this.activeProdotto=prodotti;
    //this.activeValore=valori;
    this.notes=[];
    this.refreshNotes(card.Id);
    //this.getProdottibyPPMID(card.Id);
    //this.getValorizzazioniById(card.Id);
  };



  chooseCard(chosenCard){
    if(chosenCard==""){
      this.lista1=this.datiCard;
    }else{
      this.lista1=this.datiCard.filter(function(card){
        return card.generale.ReleaseDesiderata === chosenCard
      })
    }
    //console.log( "card1",this.lista1)
    return this.lista1;
  }

  arrayRemove(arr, value) {
    return arr.filter(function(ele){
        return ele != value;
    });
  }

  chooseRelease(chosenRelease){
    if (chosenRelease=="None") {
      this.filterKit=[];
    } else if (chosenRelease=="All") {
      this.filterKit=["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10","C11","C12"];
    } else if(this.filterKit.includes(chosenRelease)){
      this.filterKit=this.arrayRemove(this.filterKit,chosenRelease);
    } else {
      this.filterKit.push(chosenRelease);
    }
    this.ricostruisciListaItems();
  }

  chooseSystem(choosenSystem){
    this.filterSystem = choosenSystem;
    this.ricostruisciListaItems();
  }

  chooseMoney(choosenMoney){
    if(choosenMoney == "siM"){
      this.filterSiM = !this.filterSiM;
    }
    if(choosenMoney == "noM"){
      this.filterNoM = !this.filterNoM;
    }
    this.ricostruisciListaItems();
  }

  chooseSemaphore(choosenSemaphore){
    if(choosenSemaphore == "R"){
      this.filterSemR=!this.filterSemR;
      this.rossoOn =! this.rossoOn;
    }
    if(choosenSemaphore == "V"){
      this.filterSemV=!this.filterSemV;
      this.verdeOn =! this.verdeOn;
    }
    if(choosenSemaphore == "G"){
      this.filterSemG=!this.filterSemG;
      this.gialloOn =! this.gialloOn;
    }
    //console.log(choosenSemaphore);
    this.ricostruisciListaItems();
  }
  chooseAmbito(choosenAmbito){
    //console.log(choosenAmbito);
    if(choosenAmbito == "Consumer"){
      this.filterCO=!this.filterCO;
      this.imageSrcRed =! this.imageSrcRed
      /* console.log(this.filterCO) */
    }
    if(choosenAmbito == "Business & Top Client"){
      this.filterBU=!this.filterBU;
      /* console.log(this.filterBU) */
      this.imageSrcBlu =! this.imageSrcBlu;
    }
    this.ricostruisciListaItems();
  }

  //Lista Prodotti in costruzione
  ricostruisciListaProd(){
    return this.listaprodotti;
  }

  ricostruisciListaVal(){
    return this.dictvalorizzazioni;
  }

  // Ricostruzione lista
  // filtro kit --> lista2 --> filtro BO/CU --> lista3 --> filtro valorizzato --> lista 4 --> filtro semaforo --> lista 5
  ricostruisciListaItems(){
    //console.log("Ricostruzione lista ...")
    let lista1=this.listaoriginale;
    /* console.log("lista1")
    console.log(lista1) */
    let lista2=[];
    let lista3=[];
    let lista4=[];
    let lista5=[];
    let lista6=[];
    console.log('isLoadValorizzazioni impostato a true')
    this.isLoadValorizzazioni=true;
    //FILTRO PER KIT
    if (this.filterKit.length == 0){
        lista2=lista1;
    } else {
        lista2=lista1.filter(item => {
            if(this.filterKit.includes(item.generale.kit)){
              return(true)
            }
            return(false)
        });
    }


    //FILTRO PER AMBITO
    if ((this.filterBU == false) && (this.filterCO == false)){
          lista3 = lista2;
    } else {
          lista3=lista2.filter(item => {
            if (((this.filterBU==true) && (item.fattibilita.Categoria =="Business & Top Client")) ||
                ((this.filterCO==true) && (item.fattibilita.Categoria =="Consumer")) ){
              return(true);
            }
            return(false);
          })
    }
    //FILTRO PER VALORIZZATO
    if ((this.filterSiM == false) && (this.filterNoM == false)){
      lista4 = lista3;
    } else {
      lista4=lista3.filter(item => {
        if (((this.filterSiM==true) && (Number(item.fattibilita.Totale)>0)) ||
            ((this.filterNoM==true) && (Number(item.fattibilita.Totale)==0))) {
          return(true);
        }
        return(false);
      });
    }
    //FILTRO PER SEMAFORO/STATO
    if ((this.filterSemV == false) && (this.filterSemG == false) && (this.filterSemR == false)){
      lista5 = lista4;
    } else {
      lista5=lista4.filter(item => {
        if (((this.filterSemV==true) && (item.fattibilita.Semaforo=='V')) ||
            ((this.filterSemG==true) && (item.fattibilita.Semaforo=='G')) ||
            ((this.filterSemR==true) && (item.fattibilita.Semaforo=='R')) ){
              return(true);
        }
        return(false);
      })

    }
  //FILTRO PER SISTEMA
  if (!this.filterSystem){
    lista6 = lista5;
  } else {
    lista6=lista5.filter(item => {
      if (this.filterSystem == item.fattibilita.Sistema && item.fattibilita.Sistema) {
        return(true);
      }
      return(false);
    });
  }
    let total = 0;
    let Moi = 0;
    for(let data of lista6){
      total += data.fattibilita.Totale;
      Moi += data.fattibilita.MOI;
      this.totaleAttivita=total;
      this.totaleMoi = Moi;
    }
    this.listaUtile=lista6;
    return(lista6);
  }


  chooseRelease1(chosenRelease){
    if(chosenRelease == ""){
      this.lista2=this.datiCard
    }else{
      this.lista2=this.lista1.filter(function(release){
        return release.generale.ReleaseDesiderata ===chosenRelease
      })
    }
    return this.lista2;
  }


  chooseColor(choosenColor){
    if(choosenColor == ""){
      this.lista4=this.datiCard;
    }else{
      this.lista4=this.datiCard.filter(function(color){
        return color.fattibilita.Semaforo === choosenColor
      })
    }
    return this.lista4;
  }

  goProdottoDetails(prodotto: Card, valori: Card){
    this.homepage=false;
    this.splashscreen=false;
    this.release=false;
    this.prodotto=true;
    this.login=false;
    this.addCard=false;
    this.valorizzazione=false;
    this.activeProdotto=prodotto;
  }

  goValDetails(valori: Valorizzazione){
    console.log(valori)
    this.homepage=false;
    this.splashscreen=false;
    this.valorizzazione=true;
    this.release=false;
    this.prodotto=false;
    this.login=false;
    this.addCard=false;
    this.activeValore=valori;
  }

  showFormLogin(){
      this.homepage=false;
    this.splashscreen=false;
    this.release=false;
    this.prodotto=false;
    this.login=true;
  this.addCard=false;
  }


elaboraAuthFromBe(response: any) {
  if (("betoken" in response) && ("esito" in response) && (response["esito"] == true)) {
    this.user=response;
    sessionStorage.setItem('account', JSON.stringify(response))
    sessionStorage.setItem('user', JSON.stringify(response))
    this.downloadData(this.user['betoken']);
    this.homepage=true;
    this.splashscreen=false;
    this.release=false;
    this.prodotto=false;
    this.login=false;
    this.addCard=false;
  } else {
    console.log("Not a good response")
    this.user={};
    /* console.log('non sei autorizzato');
    console.log(arg['esito']) */
    this.homepage=false;
    this.splashscreen=false;
    this.release=false;
    this.prodotto=false;
    this.valorizzazione=false;
    this.login=true;
    this.showMsg= "Il tuo account non è autorizzato ad eseguire l'accesso";
  }
}

onLoginEventGoogle(){
  console.log('Utente non presente in sessione');
  this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  this.authService.authState.subscribe((fromIdp) => {
    console.log("Returned from oauthserver (google): ")
    console.log(fromIdp);
    //this.user = user;
    //this.loggedIn = (user != null);
    //sessionStorage.setItem('user', JSON.stringify(user))
    console.log('Sending to backend ...')
    console.log(fromIdp['authToken'])
    this.auth.signinGoogle(fromIdp['idToken']).subscribe((response) => {
      console.log("Response from signinGoogle");
      console.log(response);
      this.elaboraAuthFromBe(response)
    });
  }, error=>{
    this.showMsg= error.error.msg;
  });
}


loginEventMicrosoft(){
      console.log('Login con Microsoft')
      this.msalService.loginPopup().subscribe((data: AuthenticationResult)=>{
        console.log("Response from login popup:")
        console.log(data);
        console.log("Utilizzo accessToken:")
        let token = data["accessToken"] //ms usa accessToken
        this.auth.signinMS(token).subscribe(response=>{
            console.log("Response from signinMS");
            console.log(response);
            this.elaboraAuthFromBe(response)
        })
      }, error=>{
              this.showMsg= error.error.msg;
      })
  }

  goHome(){
    this.login=false;
    this.homepage=true;
    this.splashscreen=false;
    this.valorizzazione=false;
    this.release=false;
    this.addCard=false;
    this.prodotto=false;
  }

  goRelease(){
    this.login=false;
    this.homepage=false;
    this.valorizzazione=false;
    this.splashscreen=false;
    this.release=true;
    this.addCard=false;
    this.prodotto=false;
  }

  changeGen(generalita:NgForm){
    this.inactive=false;

  }
  changeFatt(fattibilita){
    this.stop=false;
  }

  changeProd(prodotti){
    this.block=false;
    /* console.log(this.block) */
  }

  editGeneralita(f: NgForm, ID){
    //console.log(f);
    /* if(this.activeCard){
      this.modificaGeneralita(f, ID);

    } */
  }

  editFattibilita(f: NgForm){
    //console.log(f);
    if(this.activeCard){
      this.modificaFattibilita(f);
    }
  }

  editProdotto(f: NgForm){
    //console.log(f);
    if(this.activeCard){
      this.modificaProdotto(f);
    }
  }

  resetEdit(f:NgForm){
    this.inactive=true;
    this.stop=true;
    this.block=true;
  }

  modificaGeneralita(form : NgForm, ID){
    /* console.log('Modifica')
    console.log(form) */
    this.auth.editGenerale(ID, form.value).subscribe(res=>{
      //console.log(res);
      this.datiCard.length=res['data']
    })

/*     this.http.put<Card>(`/${this.activeCard.Id}`, form.value).subscribe((res)=>{
      let index=this.datiCard.findIndex(c =>c.Id =this.activeCard.Id)
      this.datiCard[index]=res;
    }) */
  }

  modificaFattibilita(form : NgForm){
    this.http.put<Card>(`URL/${this.activeCard.Id}`, form.value).subscribe((res)=>{
      let index=this.datiCard.findIndex(c =>c.Id =this.activeCard.Id)
      this.datiCard[index]=res;
    })
  }

  modificaProdotto(form : NgForm){
    this.http.put<Card>(`URL/${this.activeCard.Id}`, form.value).subscribe((res)=>{
      let index=this.datiCard.findIndex(c =>c.Id =this.activeProdotto.Id)
      this.datiCard[index]=res;
    })
  }

  logOutToHome(){
    sessionStorage.clear();
    this.homepage=false;
    this.splashscreen=false;
    this.release=false;
    this.valorizzazione=false;
    this.login=true;
    this.prodotto=false;
    this.addCard=false;
    this.user=null;
  }

  addActivity(form: NgForm){
    //console.log(form.value);
    //this.http.post<Card>("URL",form.value).subscribe((res:Card)=>{
     // this.activeCard.push(res);
    //});
  }

  getProdotti(){
    this.auth.loadProdotti(this.user).subscribe(res=>{
      /* console.log('********PRODOTTI*********');
      console.log(res); */
      this.elaboraRispostaProdotti(res);
    })
  }

  /*
  getProdottibyPPMID(ppmid){
    this.auth.loadProdottiByPPMID(this.user["betoken"], ppmid).subscribe(res=>{
      console.log('********PRODOTTI*********');
      console.log(res);
      this.elaboraRispostaProdotti(res);
    })
  }
  */

  addNote(nota: any){
    //console.log(nota);
    /* this.http.post<Card>(environment.reportUrl,form.value).subscribe((res:Card)=>{
      this.activeCard.push(res);
    });*/
    //console.log("Eccomi2222")
    this.auth.storeNote(nota, this.user["betoken"]).subscribe(res=>{
      //console.log(res);
      this.refreshNotes(nota["ppmid"]);
    })
  }

  refreshNotes(ppmid){
   /*  console.log("retrieve note")
    console.log(ppmid) */
    this.auth.getNotes(ppmid, this.user["betoken"]).subscribe(res=>{
      //console.log(res);
      this.notes=res['data'];
    })
  }

  deleteNota(nota:any){
   // console.log("Evento di cancellazione catturato");
    this.auth.deleteNote(nota['id'], this.user["betoken"]).subscribe(res=>{
    //  console.log(res);
      this.refreshNotes(nota["ppmid"]);
    })
  }

 /*  editGeneralita(ppmid){
    console.log("Modifica");
    this.auth.editGeneralita(ppmid['id'], this.user).subscribe(res=>{
      console.log(res);
      this.refreshNotes(nota["ppmid"]);
    })
  } */


  resetForm(form:NgForm){
  //  console.log(form.value);
    form.reset();
  }

  reset(form: NgForm){
   // console.log(form.value);
    form.resetForm();
  }

  openPrompt(){
    let dialogRef = this.dialog.open(DialogComponent, {
      width: '250px'
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  ngOnInit() {
    if(sessionStorage.getItem('user')){
      console.log('Utente presente in sessione. ricarico dati');
      this.user=JSON.parse(sessionStorage.getItem('user'));
      console.log(this.user)
      this.downloadData(this.user['betoken']);
      this.homepage=true;
      this.splashscreen=false;
      this.release=false;
      this.prodotto=false;
      this.login=false;
      this.addCard=false;
      this.valorizzazione=false;
    } else {
      console.log("Utente non presente in sessione: pannello di login")
    }
  } 
}
