export const environment = {
    production: true,
    reportUrl1:            '../assets/json/report.json',
    ifAuthedUrl1:          '../assets/json/ifAuthed.json',
    signintobackendUrl1:   '../assets/json/signinwithtoken.json',
    editGenerale:         'https://apigatewaytest.tim.it:443/ffowprj/editgenerale',
    notesUrl:              'https://apigatewaytest.tim.it:443/ffowprj/getnotes',
    reportUrl:            'https://apigatewaytest.tim.it:443/ffowprj/getreport',
    prodottiUrl:            'https://apigatewaytest.tim.it:443/ffowprj/getprodotti',
    valorizzazioniUrl:            'https://apigatewaytest.tim.it:443/ffowprj/getvalorizzazioni',
    prodottibyppmidUrl:            'https://apigatewaytest.tim.it:443/ffowprj/prodotti',
    valorizzazionibyId:            'https://apigatewaytest.tim.it:443/ffowprj/valorizzazioni',
    reportsUrl:            'https://apigatewaytest.tim.it:443/ffowprj/getreports',
    signintobackendUrl:   'https://apigatewaytest.tim.it:443/ffowprj/signinwithtoken',
    signintobackendMS:   'https://apigatewaytest.tim.it:443/ffowprj/signinwithtokenMS',
    ifAuthedUrl:          'https://apigatewaytest.tim.it:443/ffowprj/ifAuthed',
    ifAuthedMS:          'https://apigatewaytest.tim.it:443/ffowprj/ifAuthedMS',
    storeNote:              'https://apigatewaytest.tim.it:443/ffowprj/storenote',
    getNote:              'https://apigatewaytest.tim.it:443/ffowprj/getnote',
    deleteNote:              'https://apigatewaytest.tim.it:443/ffowprj/deletenote',
    redirectUriMs:          'https://www.omniwatchprojects.it',
    loginMs:              'https://apigatewaytest.tim.it:443/ffowprj/loginMS',
    loginGoogle:              'https://apigatewaytest.tim.it:443/ffowprj/loginGoogle',
}