import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Prodotti } from 'src/app/models/prodotti';
import { Valorizzazione } from 'src/app/models/valorizzazione';

@Component({
  selector: 'app-valorizzazione',
  templateUrl: './valorizzazione.component.html',
  styleUrls: ['./valorizzazione.component.css']
})
export class ValorizzazioneComponent implements OnInit {
  @Input() prodotti: Prodotti[];
  @Input() activeProdotto;
  @Input() block: boolean=true;
  @Output() clickRelease: EventEmitter<any> = new EventEmitter();
  @Output() onChangeVal: EventEmitter<any> = new EventEmitter();
  @Output() onReturnEdit: EventEmitter<any> = new EventEmitter();
  @Output() onOpenDialog: EventEmitter<any> = new EventEmitter<any>();
  @Input() activeValore;
  @Input() valori: Valorizzazione[];

  constructor() { }

  changeValorizzazione(prodotto){
    this.onChangeVal.emit(prodotto);
  }

  signOut(){}

  returnRelease(){
    this.clickRelease.emit();
  }

  returnEdit(f:NgForm){
    this.onReturnEdit.emit(f);
  }

  openDialog(){
    this.onOpenDialog.emit();
  }
  ngOnInit(): void {
  }

}
