<mat-sidenav-container style="height: 100%;" >
  <mat-sidenav style="width: 65%;" #sidenav fixedInViewport="true" [opened]="false">
    <mat-toolbar style="color: #0032a1; text-align: center; display: block;  box-shadow: 0px 10px 12px #e4e3e3; border-bottom-left-radius: 25px; border-bottom-right-radius: 25px;">
      <h4 style="margin-top: 5vw;">MENU'</h4>
    </mat-toolbar>
    <mat-nav-list>
      <a routerLink="/foto" mat-list-item><i style="margin: 3vw;" class="fa fa-save icon-large"></i>Installa l'app</a>
    <a (click)="signOut()" mat-list-item><i style="margin: 3vw;" class="fa fa-sign-out icon-large"></i>Log Out</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content style="background-color: white;">
    <div class="max-contenitore" style="position: fixed; width: 100%;">
      <mat-toolbar  class="position-fixed">
        <div class="intestazione">
          <img src="assets/img/timBlu.svg" style="margin-left: 5vw; margin-right: 2vw;">
        </div>
        <span class="example-spacer"></span>
        <div *ngIf="user">
          <div>
          <span id="nome-utente">{{user.name}}</span>
          </div>
        </div>
        <button id="menu" mat-icon-button (click)="sidenav.toggle()"  fxShow="true" fxHide.gt-sm>
          <mat-icon>menu</mat-icon>
        </button>
    </mat-toolbar>
    <div class="contenitore-cerca" style="background: white; text-align: center; position: absolute; width: 100%; margin-top: 14vw; box-shadow: 0px 10px 12px #e4e3e3; border-bottom-left-radius: 25px; border-bottom-right-radius: 25px;">
      <div *ngIf="isVisible">
          <div class="blocco-tasti"  style="display:flex;padding:3px;">
            <table width="50%" style="margin-right: 2vw;" >
              <tr>
              <td>
              <div id="ambito" style="margin-left:5vw;text-align: center; width: 25vw; display: inline-flex;">
              <div class="rosso" style="display: contents;">
                <img src="{{imageSrcRed ? 'assets/img/CO_grigio.png': 'assets/img/CO_rosso.png'}}"  (click)="chooseAmbitoRed('Consumer')">
              </div>
              <div class="blu" style="display: contents;">
                <img src="{{imageSrcBlu ? 'assets/img/BU_grigio.png': 'assets/img/BU_blu.png'}}" (click)="chooseAmbitoBlu('Business & Top Client')">
              </div>
              </div>
              </td><td>
              <div id="valorizzato" style="margin-bottom: 3vw; text-align: center; display: flex; width: 25vw;">
                <button mat-mini-fab  [color]="setclassborder('siM')"   (click)="chooseMoney('siM')">€</button>
                <button mat-mini-fab style="margin-left: 2vw;"  [color]="setclassborder('noM')" (click)="chooseMoney('noM')">&#172;€</button>
              </div>
              </td><td>
              </td>
              </tr>
            </table>
            <div class="stato" style=" margin-bottom: 3vw; display:flex;text-align: center; width: 40vw;">
              <img style="width: 10vw; margin-right: 2vw;" src="{{verdeOn ? 'assets/img/VerdeOff.svg' : 'assets/img/VerdeOn.svg'}}" (click)="chooseSemaforoVerde('V')">
              <img style="width: 10vw; margin-right: 2vw;" src="{{gialloOn ? 'assets/img/GialloOff.svg' : 'assets/img/GialloOn.svg'}}" (click)="chooseSemaforoGiallo('G')">
              <img style="width: 10vw; " src="{{rossoOn ? 'assets/img/RossoOff.svg' : 'assets/img/RossoOn.svg'}}" (click)="chooseSemaforoRosso('R')">
            </div>
          </div>
          <div class="search-cards">
            <div class="filtri-superiori">
              <input type="text" class="form-control" name="search" [(ngModel)]="term" placeholder="&#61442; Cerca" autocomplete="off">
              <mat-form-field style="width: 6em;">
                <mat-label style="font-size: 13px; resize: none;">Sistemi:</mat-label>
                <mat-select [ngModel]="myValue" name="sistemi">
                  <mat-option *ngFor="let x of user.competenza.sistemi" value="{{x}}" name="sistemi" (click)="filterSystems(x)">
                   {{x}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <button style="margin-left: 10px; height: 3em;" mat-raised-button (click)="refreshCards($event)">Refresh</button>
            </div>
          </div>
      </div>
      <div style="color: #B5BFCB" *ngIf="cards | filter: term as result">
        <table style="width: 100%; text-align: center; margin-top: 1em;" >
          <tr>
            <td>Attività: {{result.length}}</td>
            <td>&euro;: {{totaleAttivita | number: '2.' :'en-US'}}</td>
            <td>MOI: {{totaleMoi | number: '2.':'en-US'}}</td>
          </tr>
        </table>
      </div>
      <div class="menuBarre" style="height: 1em;" (click)="toggle()">
        <hr>
        <hr>
      </div>
    </div>
    </div>
    <div  *ngIf="isLoadValorizzazioni" class="content" [ngStyle]="{'margin-top': isVisible === true ? '18em' : '10em' }" >
      <mat-card style="box-shadow: none;" (click)="goRelease(card)" *ngFor="let card of cards  | filter:term ">
        <div class="inlinea">
          <span class="cod">{{card.generale.CodiceAGESP}}</span>
          <img  *ngIf="card.fattibilita.Categoria === 'Consumer'" style="margin-left: 80%; margin-top: -18%;" src="{{card.fattibilita.Categoria === 'Consumer' ? 'assets/img/CO_rosso.png': 'assets/img/BU_blu.png'}}" alt="">
          <img *ngIf="card.fattibilita.Categoria === 'Business & Top Client'" style="margin-left: 80%; margin-top: -18%;" src="{{card.fattibilita.Categoria === 'Business & Top Client' ? 'assets/img/BU_blu.png': 'assets/img/CO_rosso.png'}}" alt="">
          <span id="circle" [style.backgroundColor]="getCardColour(card.fattibilita.Semaforo)">
            <p style="display: none;">{{card.fattibilita.Semaforo}}</p>
          </span>
        </div>
       <div class="id">
          {{card.generale.Id}}
        </div>
        <div class="description">{{card.generale.Requisito}}</div>
        <div style="font-style: oblique;font-size: 12px;">{{card.fattibilita.OwnerSDP}}</div>
        <div class="box">
        </div>

        <div *ngIf="card.ordinato">
          <div style="transform: rotate(-45deg); width: 5px; margin-left: 100%; margin-bottom: -13%; color: #ffffff; resize:none">{{card.ordinato}}</div>
          <img style="width: 15%; margin-left: 91.5%; margin-bottom: -6.5%;" src="assets/img/badge_2.png">
        </div>
      </mat-card>
    </div>
<footer style="background: white; position: fixed; text-align: center; color: #B5BFCB;   box-shadow: 0px -10px 12px #e4e3e3; border-top-left-radius: 25px; border-top-right-radius: 25px;">
    <div class="menuBarre" style="height: 1.5em;" (click)="toggleFooter()">
      <hr>
      <hr>
    </div>
  <div id="bottoni" *ngIf="isVisibleBlockButton">
    <button mat-mini-fab color="config" class="bottone" (click)="chooseRelease('All')">All</button>
    <button mat-mini-fab color="config" class="bottone" (click)="chooseRelease('None')">None</button>
    <button mat-mini-fab class="bottone" [color]="setclassborder('C1')"   (click)="chooseRelease('C1')">C1</button>
    <button mat-mini-fab class="bottone"  [color]="setclassborder('C2')"   (click)="chooseRelease('C2')">C2</button>
    <button mat-mini-fab class="bottone"  [color]="setclassborder('C3')"   (click)="chooseRelease('C3')">C3</button>
    <button mat-mini-fab class="bottone"  [color]="setclassborder('C4')"   (click)="chooseRelease('C4')">C4</button>
    <button mat-mini-fab class="bottone"  [color]="setclassborder('C5')"   (click)="chooseRelease('C5')">C5</button>
    <button mat-mini-fab class="bottone"  [color]="setclassborder('C6')"   (click)="chooseRelease('C6')">C6</button>
    <button mat-mini-fab class="bottone"  [color]="setclassborder('C7')"   (click)="chooseRelease('C7')">C7</button>
    <button mat-mini-fab class="bottone"  [color]="setclassborder('C8')"   (click)="chooseRelease('C8')">C8</button>
    <button mat-mini-fab class="bottone"  [color]="setclassborder('C9')"   (click)="chooseRelease('C9')">C9</button>
    <button mat-mini-fab class="bottone"  [color]="setclassborder('C10')"  (click)="chooseRelease('C10')">C10</button>
    <button mat-mini-fab class="bottone"  [color]="setclassborder('C11')"  (click)="chooseRelease('C11')">C11</button>
    <button mat-mini-fab class="bottone"  [color]="setclassborder('C12')"  (click)="chooseRelease('C12')">C12</button>
  </div>
</footer>
  </mat-sidenav-content>
</mat-sidenav-container>




