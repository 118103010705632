import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './components/homepage/homepage.component';
import { ProdottoComponent } from './components/prodotto/prodotto.component';
import { ReleaseComponent } from './components/release/release.component';
import { LoginGuard } from './login.guard';

const routes: Routes = [
  {path: '', redirectTo:'/splash', pathMatch:'full'},
  {path: 'splash', component: HomepageComponent},
  {path: 'home', component: HomepageComponent, canActivate: [LoginGuard]},
  {path: 'release', component: ReleaseComponent},
  {path: 'prodotto', component: ProdottoComponent, canActivate: [LoginGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
